ion-menu {
	ion-content {
		--background:var(--ion-item-background, var(--ion-background-color, #fff));
	}

	&.md {
		ion-content {
			--padding-start:8px;
			--padding-end:8px;
			--padding-top:20px;
			--padding-bottom:20px;
		}

		ion-list {
			padding: 20px 0;
		}

		ion-note {
			margin-bottom: 30px;
		}

		ion-list-header, ion-note {
			padding-left: 10px;
		}

		ion-list {
			&:first-of-type ion-list-header {
				font-size: 22px;
				font-weight: 600;
				min-height: 20px;
			}

			&:not(:first-of-type) ion-list-header {
				font-size: 16px;
				margin-bottom: 18px;
				color: #757575;
				min-height: 26px;
			}

			&:not(:last-of-type) {
				border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
			}
		}

		ion-item {
			--padding-start:10px;
			--padding-end:10px;
			border-radius: 4px;

			&.selected {
				--background:rgba(var(--ion-color-primary-rgb), 0.14);

				ion-icon {
					color: var(--ion-color-primary);
				}
			}

			ion-icon {
				color: #616e7e;
			}

			ion-label {
				font-weight: 500;
			}
		}
	}

	&.ios {
		ion-content {
			--padding-bottom:20px;
		}

		ion-list {
			padding: 20px 0 0 0;
		}

		ion-note {
			line-height: 24px;
			margin-bottom: 20px;
		}

		ion-item {
			--padding-start:16px;
			--padding-end:16px;
			--min-height:50px;

			ion-icon {
				font-size: 24px;
				color: #73849a;
			}

			.selected ion-icon {
				color: var(--ion-color-primary);
			}
		}

		ion-list#labels-list ion-list-header {
			margin-bottom: 8px;
		}

		ion-list-header {
			padding-left: 16px;
			padding-right: 16px;
		}

		ion-note {
			padding-left: 16px;
			padding-right: 16px;
			margin-bottom: 8px;
		}
	}
}

ion-note {
	display: inline-block;
	font-size: 16px;
	color: var(--ion-color-medium-shade);
}

ion-item.selected {
	--color:var(--ion-color-primary);
}
