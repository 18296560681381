.skills-page {
	padding: 16px;

	.skills {
		display: flex;

		flex-wrap: wrap;

		> * {
			margin-right: 16px;

			width: 400px;
		}
	}
}
