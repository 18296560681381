.wordle-cell {
	outline: var(--ion-color-secondary) solid 1px;
	width: 3em;
	height: 3em;
	text-align: center;

	&.dead {
		background-color: var(--ion-background-color);
	}

	&.known {
		background-color: var(--ion-color-warning);
	}

	&.confirmed {
		background-color: var(--ion-color-success);
	}
}
