.skills-group {
	.skills-group-name {
		color: var(--ion-color-secondary);
		font-size: 20px;
		line-height: 26px;
	}

	.skill {
		.skill-name {
			color: var(--ion-color-tertiary);
		}
	}
}
