.work-entry {
	.company-name {
		color: var(--ion-color-secondary);
		font-size: 20px;
		line-height: 26px;
	}

	.position {
		color: var(--ion-color-tertiary);
	}

	.duration {
		color: var(--ion-color-tertiary);
	}
}
