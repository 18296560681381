.education-page {
	padding: 16px;

	.summary {
		.school-name {
			color: var(--ion-color-secondary);
		}

		.degree {
			margin-top: 4px;
		}

		.degree, .duration {
			color: var(--ion-color-tertiary);
		}
	}

	.courses-section {
		.courses-header {
			color: var(--ion-color-secondary);
		}

		.courses {
			display: flex;
			flex-wrap: wrap;

			margin-left: 4px;
		}
	}
}
