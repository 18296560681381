.anchor-card {
	width: 300px;

	ion-card-content {
		div {
			margin-bottom: 8px;
		}

		a {
			font-size: 20px;
			margin-top: 16px;
		}
	}
}
